import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';

import { LeagueFilters } from './LeagueFilters';
import { MobileLeagueFilters } from './MobileLeagueFilters';

export interface FilterState {
	keyword: string;
	status: 'active' | 'completed' | string;
	country: {
		id: string;
		stateId: string;
	};
	playerGroupsId: string;
	formatsId: string;
	weekDays: {
		parameter: string;
		value: string;
	};
}

export const initialFilters: FilterState = {
	keyword: '',
	status: 'active',
	country: {
		id: '',
		stateId: ''
	},
	playerGroupsId: '',
	formatsId: '',
	weekDays: {
		parameter: '',
		value: ''
	}
};

interface FiltersProps {
	onSubmit: (filters: FilterState) => void;
}

export const Filters = ({ onSubmit }: FiltersProps) => {
	const searchParams = useSearchParams();
	const [filters, setFilters] = useState<FilterState>({
		keyword: searchParams?.get('keyword') || '',
		status: searchParams?.get('status') || 'active',
		country: {
			id: searchParams?.get('country') || '',
			stateId: searchParams?.get('state') || ''
		},
		playerGroupsId: searchParams?.get('player_groups') || '',
		formatsId: searchParams?.get('formats') || '',
		weekDays: {
			parameter: searchParams?.get('weekdays_parameter') || '',
			value: searchParams?.get('weekdays_value') || ''
		}
	});
	const router = useRouter();
	const { pathname, query } = router;

	const updateFilter = <T extends FilterState, K extends keyof T>(key: K, value: T[K]) => {
		setFilters({ ...filters, [key]: value });
	};

	const submitFilters = React.useCallback(
		(internalFilters?: FilterState) => {
			onSubmit(internalFilters ? internalFilters : filters);
		},
		[filters, onSubmit]
	);

	const resetFilters = () => {
		if (Object.keys(query).length > 0) router.push(pathname);

		setFilters(initialFilters);
		onSubmit(initialFilters);
	};

	useEffect(() => {
		submitFilters();
	}, [filters.keyword]);

	return (
		<>
			<div className="flex flex-col gap-4 custom-lg:hidden">
				<div className="px-4">
					<DebounceInput
						type="text"
						className="h-12 w-full rounded-md border border-gray-300 bg-white px-3 py-1.5 text-sm focus:border-gray-200 focus:outline-none focus:ring focus:ring-gray-100"
						placeholder="Search for Leagues..."
						minLength={2}
						debounceTimeout={750}
						value={filters.keyword}
						onChange={(e) => {
							updateFilter('keyword', e.target.value);
						}}
					/>
				</div>
				<MobileLeagueFilters
					filters={filters}
					updateFilter={(key, value) => {
						updateFilter(key, value);
					}}
					resetFilters={resetFilters}
					onSubmit={submitFilters}
				/>
			</div>
			<div className="hidden custom-lg:block">
				<LeagueFilters
					filters={filters}
					updateFilter={(key, value) => {
						updateFilter(key, value);
					}}
					resetFilters={resetFilters}
					onSubmit={submitFilters}
				/>
			</div>
		</>
	);
};
