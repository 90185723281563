import { FilterState } from '@/modules/league/list/components/Filters';

export const getLeagueSearchParams = (filters: FilterState) => {
	const newSearchParams = new URLSearchParams();
	const { keyword, country, playerGroupsId, formatsId, weekDays, status } = filters;

	if (keyword) {
		newSearchParams.set('keyword', keyword);
	}
	if (country.id) {
		newSearchParams.set('country', country.id);
	}
	if (country.stateId) {
		newSearchParams.set('state', country.stateId);
	}
	if (playerGroupsId) {
		newSearchParams.set('player_groups', playerGroupsId);
	}
	if (formatsId) {
		newSearchParams.set('formats', formatsId);
	}
	if (weekDays.parameter) {
		newSearchParams.set('weekdays_parameter', weekDays.parameter);
	}
	if (weekDays.value) {
		newSearchParams.set('weekdays_value', weekDays.value);
	}
	if (status) {
		newSearchParams.set('status', status);
	}

	return newSearchParams.toString();
};
