import MobileDetect from 'mobile-detect';
import moment from 'moment';
import { GetServerSideProps, Metadata } from 'next';
import Head from 'next/head';
import React from 'react';

import { RootLayout } from '@/layouts/RootLayout';
import { getSessionPagesWithRoles } from '@/lib/getSessionPagesWithRoles';
import { getPagesIsImpersonatingSS } from '@/lib/sessionv2/getPagesIsImpersonatingSS';
import { LeaguesList } from '@/modules/league/list/LeaguesList';

import { NextPageWithLayout } from '../_app';

export const getServerSideProps = (async ({ req, res }) => {
	let isMobile = false;
	let ipAddress = '';
	if (req.headers['user-agent']) {
		isMobile = new MobileDetect(req.headers['user-agent']).mobile() !== null;
	}
	if (req.headers['x-forwarded-for']) {
		ipAddress = req.headers['x-forwarded-for']?.toString();
	}

	const session = await getSessionPagesWithRoles(req, res);
	const metadata = generateMetadata();
	const isImpersonationInProcess = getPagesIsImpersonatingSS(req);

	return {
		props: {
			isMobile,
			ipAddress,
			session,
			metadata,
			isImpersonationInProcess,
			isLeaguePage: true
		}
	};
}) satisfies GetServerSideProps;

const generateMetadata = (): Metadata => {
	return {
		title: 'Explore Pickleball Leagues | Local, National & Professional Leagues',
		description:
			'Discover top-tier pickleball leagues in the USA, Canada and globally featuring national and professional events, pickleball schedules, and updates.',
		keywords:
			'Pickleball News, Pro Pickleball, Tournament Site, Bracket Generator, Pickleball Tournaments, Pickleball Clinics, Pickleball League, Pickleball Brackets, Elimination Bracket, Round Robin, Pool Play, Blind Draw, Singles, Doubles, Mix Doubles, Mens, Womens, Teams, Pickleball, Pickleball Paddle, Paddle, Pickleball Registrations, USAP, Pickleball Canada',
		openGraph: {
			siteName: 'Pickleball.com',
			url: `${process.env.NEXT_PUBLIC_PL_URL}/leagues`,
			title: 'Explore Pickleball Leagues | Local, National & Professional Leagues',
			description:
				'Discover top-tier pickleball leagues in the USA, Canada and globally featuring national and professional events, pickleball schedules, and updates.',
			images: [
				{
					url: `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/pickleball.png`
				}
			],
			type: 'website'
		},
		twitter: {
			card: 'summary_large_image',
			site: 'pickleball',
			title: 'Explore Pickleball Leagues | Local, National & Professional Leagues',
			description:
				'Discover top-tier pickleball leagues in the USA, Canada and globally featuring national and professional events, pickleball schedules, and updates.',
			images: [
				{
					url: `https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/pickleball.png`
				}
			]
		},
		alternates: {
			canonical: `${process.env.NEXT_PUBLIC_PL_URL}/leagues`
		}
	};
};
const LeaguesPage: NextPageWithLayout = ({ ipAddress, metadata }: any) => {
	return (
		<>
			<Head>
				<title>{metadata.title}</title>
				<meta key="description" name="description" content={metadata.description} />
				<meta property="keywords" content={metadata.keywords} />
				<meta property="og:type" content={metadata.openGraph.type} />
				<meta property="og:url" content={metadata.openGraph.url} />
				<link rel="canonical" href={metadata.alternates.canonical} />
				<meta property="og:site_name" content={metadata.openGraph.siteName} />
				<meta property="og:title" content={metadata.openGraph.title} />
				<meta property="og:image" content={metadata.openGraph.images[0].url} />
				<meta property="og:description" content={metadata.openGraph.description} />
				<meta name="twitter:card" content={metadata.twitter.card} />
				<meta name="twitter:site" content={metadata.twitter.site} />
				<meta name="twitter:title" content={metadata.twitter.title} />
				<meta name="twitter:description" content={metadata.twitter.description} />
				<meta name="twitter:image" content={metadata.twitter.images[0].url} />
				<meta name="COPYRIGHT" content={`(c) 2015-${moment().get('year')} Pickleball OpCo LLC`} />
			</Head>
			<LeaguesList ipAddress={ipAddress} />
		</>
	);
};

LeaguesPage.getLayout = function (page) {
	return <RootLayout>{page}</RootLayout>;
};

export default LeaguesPage;
