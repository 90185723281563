import { faAngleDown, faAngleUp, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@pickleballinc/react-ui';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { Country, State } from 'protos/model';
import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';

import { Menu, MenuContent, MenuItem, MenuTrigger } from '@/components/Menu';

import { FilterState } from './Filters';

export const playerGroupData = [
	{
		label: 'Mens',
		value: '1'
	},
	{
		label: 'Womens',
		value: '2'
	},
	{
		label: 'Mixed',
		value: '3'
	},
	{
		label: 'Coed',
		value: '4'
	}
];

export const formatsData = [
	{
		label: 'Doubles',
		value: 'DP' //DS
	},
	{
		label: 'Singles',
		value: '2'
	},
	{
		label: 'Skinny Singles',
		value: '6'
	}
];

export type Weekday = {
	label: string;
	parameter: 'Day_Su' | 'Day_Mo' | 'Day_Tu' | 'Day_We' | 'Day_Th' | 'Day_Fr' | 'Day_Sa';
	value: string;
};

export const weekdayData: Weekday[] = [
	{
		label: 'Sunday',
		parameter: 'Day_Su',
		value: '1'
	},
	{
		label: 'Monday',
		parameter: 'Day_Mo',
		value: '2'
	},
	{
		label: 'Tuesday',
		parameter: 'Day_Tu',
		value: '3'
	},
	{
		label: 'Wednesday',
		parameter: 'Day_We',
		value: '4'
	},
	{
		label: 'Thursday',
		parameter: 'Day_Th',
		value: '5'
	},
	{
		label: 'Friday',
		parameter: 'Day_Fr',
		value: '6'
	},
	{
		label: 'Saturday',
		parameter: 'Day_Sa',
		value: '7'
	}
];

export type StatusType = 'active' | 'completed';

interface LeagueFiltersProps {
	filters: FilterState;
	updateFilter: <T extends FilterState, K extends keyof T>(key: K, value: T[K]) => void;
	resetFilters: () => void;
	onSubmit: (internalFilters?: FilterState) => void;
}

export const LeagueFilters = ({ filters, updateFilter, resetFilters, onSubmit }: LeagueFiltersProps) => {
	const { data: countries } = useQuery(
		['countries'],
		async () => {
			const response = await fetch('/api/getLeagues/getCountries');
			const responseData = (await response.json()) as { data: { results: Country[] } };
			return responseData.data;
		},
		{
			refetchOnWindowFocus: false,
			initialData: {
				results: []
			}
		}
	);

	const { data: states } = useQuery(
		['states', filters.country.id],
		async () => {
			const response = await fetch(`/api/getLeagues/getStates?countryId=${filters.country.id}`);
			const responseData = (await response.json()) as { data: { results: State[] } };
			return responseData.data;
		},
		{
			refetchOnWindowFocus: false,
			enabled: filters.country.id !== '',
			initialData: {
				results: []
			}
		}
	);
	const [formatsGroupPlayersData, setFormatsGroupPlayersData] = useState(formatsData);
	const getFormatsForPlayerGroup = (playerGroupValue: string) => {
		if (playerGroupValue === '3' && formatsData[1] && formatsData[2]) {
			return setFormatsGroupPlayersData([formatsData[1], formatsData[2]]);
		} else {
			return setFormatsGroupPlayersData(formatsData);
		}
	};

	return (
		<>
			<div className="flex w-full justify-center py-2">
				<div className="flex w-full max-w-screen-xl flex-col items-center justify-between gap-2 px-4 lg:flex-row">
					<div className="flex w-full gap-2 lg:w-fit">
						<span className="relative w-full lg:w-[274px]">
							<DebounceInput
								onKeyUp={(event: KeyboardEvent) => {
									if (event.key === 'Enter') {
										onSubmit();
									}
								}}
								type="text"
								className={`border-1 h-[44px] w-full !rounded-md  border !border-gray-300 bg-white !px-3 py-1.5  focus:border-gray-200 focus:outline-none focus:ring focus:ring-gray-100`}
								placeholder="Search for a League..."
								minLength={2}
								debounceTimeout={750}
								value={filters.keyword}
								onChange={(e) => {
									updateFilter('keyword', e.target.value);
								}}
							/>
						</span>
					</div>

					<div className="flex items-center justify-between gap-2 ">
						<div>
							<Menu width={160}>
								<MenuTrigger asChild>
									{(opened) => {
										return (
											<Button
												variant="secondary"
												className={clsx(
													'!border-1 !h-[44px] !rounded-lg !border-gray-300 !bg-white !px-3 !font-normal !text-gray-500 transition-all'
												)}
												suffixIcon={<FontAwesomeIcon icon={opened ? faAngleUp : faAngleDown} />}
											>
												Status
											</Button>
										);
									}}
								</MenuTrigger>
								<MenuContent className="animate-fade-in-down w-fit min-w-[200px] rounded-lg border border-gray-300 bg-white drop-shadow-md">
									<MenuItem
										activeClasses="!bg-experiment text-white"
										isActive={filters.status === 'active'}
										onClick={() => {
											updateFilter('status', 'active');
										}}
									>
										Active
									</MenuItem>
									<MenuItem
										activeClasses="!bg-experiment text-white"
										isActive={filters.status === 'completed'}
										onClick={() => {
											updateFilter('status', 'completed');
										}}
									>
										Completed
									</MenuItem>
								</MenuContent>
							</Menu>
						</div>

						<div>
							<Menu placement="bottom-start">
								<MenuTrigger asChild>
									{(opened) => {
										return (
											<Button
												variant="secondary"
												className={clsx(
													'!border-1 !h-[44px] !rounded-lg !border-gray-300 !bg-white !px-3 !font-normal !text-gray-500 transition-all'
												)}
												suffixIcon={<FontAwesomeIcon icon={opened ? faAngleUp : faAngleDown} />}
											>
												Country
											</Button>
										);
									}}
								</MenuTrigger>
								<MenuContent className="animate-fade-in-down w-fit min-w-[200px] rounded-lg border border-gray-300 bg-white drop-shadow-md">
									<>
										<MenuItem
											activeClasses="!bg-experiment text-white"
											onClick={() => {
												updateFilter('country', {
													id: '',
													stateId: ''
												});
											}}
										>
											All
										</MenuItem>
										{countries.results.map((ct) => {
											return (
												<MenuItem
													activeClasses="!bg-experiment text-white"
													key={ct.id}
													isActive={filters.country.id === ct.id.toString()}
													onClick={() => {
														updateFilter('country', { id: ct.id.toString(), stateId: '' });
													}}
												>
													{ct.title}
												</MenuItem>
											);
										})}
									</>
								</MenuContent>
							</Menu>
						</div>

						<div>
							<Menu width={160}>
								<MenuTrigger asChild>
									{(opened) => {
										return (
											<Button
												variant="secondary"
												className={clsx(
													'!border-1 !h-[44px] !rounded-lg !border-gray-300 !bg-white !px-3 !font-normal !text-gray-500 transition-all'
												)}
												suffixIcon={<FontAwesomeIcon icon={opened ? faAngleUp : faAngleDown} />}
											>
												State
											</Button>
										);
									}}
								</MenuTrigger>
								<MenuContent className="animate-fade-in-down w-fit min-w-[200px] rounded-lg border border-gray-300 bg-white drop-shadow-md">
									<>
										{states.results.length === 0 ? (
											<div className="py-3 text-center text-sm text-gray-400">Please first select country.</div>
										) : (
											<>
												{states.results.map((st) => {
													return (
														<MenuItem
															activeClasses="!bg-experiment text-white"
															key={st.id}
															isActive={filters.country.stateId === st.id.toString()}
															onClick={() => {
																updateFilter('country', { id: filters.country.id, stateId: st.id.toString() });
															}}
														>
															{st.title}
														</MenuItem>
													);
												})}
											</>
										)}
									</>
								</MenuContent>
							</Menu>
						</div>

						<div>
							<Menu width={320} closeOnSelection={false}>
								<MenuTrigger asChild>
									{(opened) => {
										return (
											<Button
												variant="secondary"
												className={clsx(
													'!border-1 !h-[44px] !rounded-lg !border-gray-300 !bg-white !px-3 !font-normal !text-gray-500 transition-all'
												)}
												suffixIcon={<FontAwesomeIcon icon={opened ? faAngleUp : faAngleDown} />}
											>
												Player Groups
											</Button>
										);
									}}
								</MenuTrigger>
								<MenuContent className="animate-fade-in-down w-fit min-w-[200px] rounded-lg border border-gray-300 bg-white drop-shadow-md">
									<>
										{playerGroupData.map((pg) => {
											return (
												<MenuItem
													activeClasses="!bg-experiment text-white"
													key={pg.value}
													isActive={filters.playerGroupsId === pg.value}
													onClick={() => {
														if (filters.playerGroupsId === pg.value) {
															updateFilter('playerGroupsId', '');
															getFormatsForPlayerGroup(pg.value);
														} else {
															updateFilter('playerGroupsId', pg.value);
															getFormatsForPlayerGroup(pg.value);
														}
													}}
												>
													{pg.label}
												</MenuItem>
											);
										})}
									</>
								</MenuContent>
							</Menu>
						</div>

						<div>
							<Menu width={220} closeOnSelection={false}>
								<MenuTrigger asChild>
									{(opened) => {
										return (
											<Button
												variant="secondary"
												className={clsx(
													'!border-1 !h-[44px] !rounded-lg !border-gray-300 !bg-white !px-3 !font-normal !text-gray-500 transition-all'
												)}
												suffixIcon={<FontAwesomeIcon icon={opened ? faAngleUp : faAngleDown} />}
											>
												Formats
											</Button>
										);
									}}
								</MenuTrigger>
								<MenuContent className="animate-fade-in-down w-fit min-w-[200px] rounded-lg border border-gray-300 bg-white drop-shadow-md">
									{formatsGroupPlayersData.map((fd) => {
										return (
											<MenuItem
												activeClasses="!bg-experiment text-white"
												key={fd.value}
												isActive={filters.formatsId === fd.value}
												onClick={() => {
													if (filters.formatsId === fd.value) {
														updateFilter('formatsId', '');
													} else {
														updateFilter('formatsId', fd.value);
													}
												}}
											>
												{fd.label}
											</MenuItem>
										);
									})}
								</MenuContent>
							</Menu>
						</div>

						<div>
							<Menu width={160} closeOnSelection={false}>
								<MenuTrigger asChild>
									{(opened) => {
										return (
											<Button
												variant="secondary"
												className={clsx(
													'!border-1 !h-[44px] !rounded-lg !border-gray-300 !bg-white !px-3 !font-normal !text-gray-500 transition-all'
												)}
												suffixIcon={<FontAwesomeIcon icon={opened ? faAngleUp : faAngleDown} />}
											>
												Day of the Week
											</Button>
										);
									}}
								</MenuTrigger>
								<MenuContent className="animate-fade-in-down w-fit min-w-[200px] rounded-lg border border-gray-300 bg-white drop-shadow-md">
									{weekdayData.map((wd) => {
										return (
											<MenuItem
												activeClasses="!bg-experiment text-white"
												key={wd.value}
												isActive={filters.weekDays.parameter === wd.parameter && filters.weekDays.value === wd.value}
												onClick={() => {
													if (filters.weekDays.parameter === wd.parameter && filters.weekDays.value === wd.value) {
														updateFilter('weekDays', { parameter: '', value: '' });
													} else {
														updateFilter('weekDays', { parameter: wd.parameter, value: wd.value });
													}
												}}
											>
												{wd.label}
											</MenuItem>
										);
									})}
								</MenuContent>
							</Menu>
						</div>
					</div>

					<div className="hidden animate-fade-in gap-2 lg:flex">
						<Button variant="secondary" className="bg-white" onClick={resetFilters} size="lg">
							Reset
						</Button>
						<Button
							variant="primary"
							className="bg-experiment"
							onClick={() => {
								onSubmit();
							}}
							size="lg"
						>
							Apply
						</Button>
					</div>
				</div>
			</div>
			<div className="container mx-auto mt-2">
				{filters.status && (
					<span className="mr-4 inline-flex items-center gap-1  rounded-full  bg-gray-900 px-4 py-[10px] text-sm  font-medium tracking-wide  text-white hover:bg-gray-700 ">
						{filters.status.charAt(0).toUpperCase() + filters.status.slice(1)}
						<span
							className=" ml-2 cursor-pointer  text-white"
							onClick={() => updateFilter('status', filters.status === 'active' ? 'completed' : 'active')}
						>
							<FontAwesomeIcon icon={faTimes} />
						</span>
					</span>
				)}
				{filters.country.id !== '' && (
					<span className="mr-4 inline-flex items-center gap-1  rounded-full  bg-gray-900  px-4 py-[10px] text-sm  font-medium tracking-wide  text-white hover:bg-gray-700">
						{`Country: ${countries.results.filter((c) => c.id.toString() === filters.country.id).map((c) => c.title)}`}
						<span
							className=" ml-2 cursor-pointer  text-white"
							onClick={() => {
								updateFilter('country', { id: '', stateId: '' });
							}}
						>
							<FontAwesomeIcon icon={faTimes} />
						</span>
					</span>
				)}
				{filters.country.stateId !== '' && (
					<span className="mr-4 inline-flex items-center gap-1  rounded-full bg-gray-900  px-4 py-[10px] text-sm  font-medium tracking-wide  text-white hover:bg-gray-700">
						{`State: ${states.results.filter((s) => s.id.toString() === filters.country.stateId).map((s) => s.title)}`}
						<span
							className=" ml-2 cursor-pointer  text-white"
							onClick={() => updateFilter('country', { id: filters.country.id, stateId: '' })}
						>
							<FontAwesomeIcon icon={faTimes} />
						</span>
					</span>
				)}
				{filters.playerGroupsId !== '' &&
					playerGroupData
						.filter((pg) => pg.value === filters.playerGroupsId)
						.map((pg) => {
							return (
								<span
									key={pg.value}
									className="mr-4 inline-flex items-center gap-1  rounded-full  bg-gray-900 px-4 py-[10px] text-sm  font-medium tracking-wide  text-white hover:bg-gray-700"
								>
									{pg.label}
									<span className=" ml-2 cursor-pointer  text-white" onClick={() => updateFilter('playerGroupsId', '')}>
										<FontAwesomeIcon icon={faTimes} />
									</span>
								</span>
							);
						})}
				{filters.formatsId !== '' &&
					formatsData
						.filter((fd) => fd.value === filters.formatsId)
						.map((fd) => {
							return (
								<span
									key={fd.value}
									className="mr-4 inline-flex items-center gap-1  rounded-full  bg-gray-900 px-4 py-[10px] text-sm  font-medium tracking-wide  text-white hover:bg-gray-700"
								>
									{fd.label}
									<span className=" ml-2 cursor-pointer  text-white" onClick={() => updateFilter('formatsId', '')}>
										<FontAwesomeIcon icon={faTimes} />
									</span>
								</span>
							);
						})}
				{filters.weekDays.parameter !== '' &&
					filters.weekDays.value !== '' &&
					weekdayData
						.filter((wd) => wd.parameter === filters.weekDays.parameter && wd.value === filters.weekDays.value)
						.map((wd) => {
							return (
								<span
									key={wd.value}
									className="mr-4 inline-flex items-center gap-1  rounded-full  bg-gray-900 px-4 py-[10px] text-sm  font-medium tracking-wide  text-white hover:bg-gray-700"
								>
									{wd.label}
									<span
										className=" ml-2 cursor-pointer  text-white"
										onClick={() => {
											updateFilter('weekDays', { parameter: '', value: '' });
										}}
									>
										<FontAwesomeIcon icon={faTimes} />
									</span>
								</span>
							);
						})}
			</div>
		</>
	);
};
