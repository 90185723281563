/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
import { faExternalLink, faMoneyBill } from '@fortawesome/pro-light-svg-icons';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@pickleballinc/react-ui';
import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import { Url } from 'next/dist/shared/lib/router/router';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { EventCard } from '@/components/EventCard';
import { Skeleton } from '@/components/EventCard/Skeleton';
import ImageWithFallback from '@/components/ImageWithFallback/ImageWithFallback';
import { RenderAd } from '@/components/RenderAd';
import { useSessionAuth } from '@/contexts/sessionAuth/SessionAuthProvider';
import { useGetCreatePermissions } from '@/hooks/useGetCreatePermissions';
import { getLeagueSearchParams } from '@/modules/league/list/utils/getLeagueSearchParams';
import { LeagueSearch } from '@/types/leagues.types';
import getCDNURL from '@/utils/helpers/getCDNURL';
import getPBracketsCDNURL from '@/utils/helpers/getPBracketsCDNURL';
import { hasPermission } from '@/utils/helpers/hasPermission';
import isSsoURL from '@/utils/helpers/isSsoURL';
import { checkIfValidUUID } from '@/utils/helpers/uuid';

import { Filters, FilterState } from './components/Filters';

export const LeaguesList = ({ ipAddress }: { ipAddress: string }) => {
	const { user } = useSessionAuth();

	const [activeLeague, setActiveLeague] = useState<LeagueSearch | undefined>();
	const isSuperAdminPickleball = hasPermission(['SUPER_ADMIN'], user?.session?.roles);
	const isPBSuperAdmin = hasPermission(['PICKLEBALL_BRACKETS_ADMIN'], user?.session?.roles);
	const isSuperAdmin = isSuperAdminPickleball || isPBSuperAdmin;
	const searchParams = useSearchParams();
	const [keyword, setKeyword] = useState(searchParams?.get('keyword') || '');
	const router = useRouter();
	const { clubId } = router.query;
	const totalLeaguesLoaded = useRef<number>(0);
	const [filters, setFilters] = useState<FilterState>({
		keyword: searchParams?.get('keyword') || '',
		status: searchParams?.get('status') || 'active',
		country: {
			id: searchParams?.get('country') || '',
			stateId: searchParams?.get('state') || ''
		},
		playerGroupsId: searchParams?.get('player_groups') || '',
		formatsId: searchParams?.get('formats') || '',
		weekDays: {
			parameter: searchParams?.get('weekdays_parameter') || '',
			value: searchParams?.get('weekdays_value') || ''
		}
	});
	const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery(
		['leagues', keyword, filters.keyword, filters.status, filters.country, filters.playerGroupsId, filters.formatsId, filters.weekDays, clubId],
		async ({ pageParam = 1 }) => {
			const response = await axios.get<{ data: { result: LeagueSearch[] }; statusCode: number; error?: string }>(
				'/api/getLeagues/getLeaguesSearch',
				{
					params: {
						keyword: filters.keyword,
						status: filters.status,
						country: filters.country.id,
						state: filters.country.stateId,
						player_groups: filters.playerGroupsId,
						formats: filters.formatsId,
						weekdays_parameter: filters.weekDays.parameter,
						weekdays_value: filters.weekDays.value,
						page: pageParam,
						clubId
					}
				}
			);

			totalLeaguesLoaded.current += response.data.data.result.length;
			return {
				page: pageParam,
				data: response.data.data.result
			};
		},
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			getNextPageParam: (lastPage) => {
				if (lastPage.data[0]?.RecordCount && totalLeaguesLoaded.current < lastPage.data[0]?.RecordCount && lastPage.data.length)
					return lastPage.page + 1;
				return undefined;
			}
		}
	);

	const [numberOfDifferentBannerImages, setNumberOfDifferentBannerImages] = React.useState<number>();

	const [createANewLeagueURL, setCreateANewLeagueURL] = useState<Url>('');

	const { data: createPerms } = useGetCreatePermissions('League', !!user?.session.uuid);

	// Create a League URL
	useEffect(() => {
		if (user?.session && user?.session?.uuid && createPerms?.ApprovedToCreate) {
			const url = new URL(createPerms.URLPath);
			url.searchParams.set('rurl', window.location.href);
			setCreateANewLeagueURL(url);
		} else {
			const url = new URL(`https://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}`);

			url.hostname = `contact.${url.hostname}`;
			url.pathname += 'contacts/league/create';

			setCreateANewLeagueURL(url);
		}
	}, [createPerms]);

	const contactALeagueURL = `https://contact.${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/contacts/league`;

	return (
		<>
			<RenderAd
				zoneId="679340"
				pageId={100008}
				place={0}
				ipAddress={ipAddress}
				containerClassname="mx-0.5 mt-4 sm:container sm:mx-auto"
				numberOfDifferentZoneImages={numberOfDifferentBannerImages}
				setNumberOfDifferentZoneImages={setNumberOfDifferentBannerImages}
			/>

			<div className="my-4 border-y">
				<div className="container mx-auto flex flex-wrap items-center justify-end py-4">
					{user?.session && user?.session?.uuid ? (
						<div className="flex flex-wrap items-center">
							<Link href={`${process.env.NEXT_PUBLIC_PL_URL}/leagues/user/${user?.data?.pbUuid}/my-active-league-schedule`}>
								<Button variant="primary" className="mb-2 mr-2 h-12 w-auto justify-center rounded-md font-bold md:mb-0 lg:mb-0">
									My League Schedule
								</Button>{' '}
							</Link>
							<Link href={createANewLeagueURL} rel={isSsoURL(createANewLeagueURL) ? 'nofollow' : undefined}>
								<Button variant="secondary" className="h-12 w-auto justify-center rounded-md font-bold">
									Create a New League
								</Button>
							</Link>
						</div>
					) : (
						<div className="flex w-full items-center sm:w-[unset]">
							<Link href={createANewLeagueURL} rel={isSsoURL(createANewLeagueURL) ? 'nofollow' : undefined} className="w-full">
								<Button variant="secondary" className="h-12 w-full justify-center rounded-md font-bold">
									Create a New League
								</Button>
							</Link>
						</div>
					)}
				</div>
			</div>

			<Filters
				onSubmit={(filters) => {
					setKeyword(filters.keyword);
					setFilters(filters);
					totalLeaguesLoaded.current = 0;
					router.push({
						pathname: process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME?.startsWith('localhost') ? '/leagues' : `/`,
						query: getLeagueSearchParams(filters)
					});
				}}
			/>

			{isLoading ? (
				<div className="container mx-auto mb-10 grid grid-cols-2 gap-4 py-4 md:gap-10 lg:grid-cols-4 xl:grid-cols-4">
					{Array.from(Array(8).keys()).map((index) => {
						return <Skeleton key={index} />;
					})}
				</div>
			) : (
				<>
					<div className="container mx-auto flex flex-row items-baseline py-4">
						<div className="text-2xl font-bold">Leagues</div>

						<div className="ml-4 font-bold text-gray-500">Total: {data?.pages[0]?.data[0] ? data?.pages[0].data[0]?.RecordCount : 0}</div>
					</div>
					<div className="container mx-auto grid grid-cols-2 gap-4 py-4 md:gap-10 lg:grid-cols-4 xl:grid-cols-4">
						<div className="flex w-full items-center justify-center">
							<RenderAd zoneId="688401" pageId={100008} place={0} ipAddress={ipAddress} containerClassname="w-full" />
						</div>
						{data?.pages[0] &&
							data?.pages[0].data.slice(0, 3).map((league, index) => {
								if (index < 3) {
									return (
										<LeagueCard
											key={`${league.LeagueID}-${league.LeagueTitle}`}
											league={league}
											onLeagueClick={(league) => setActiveLeague(league)}
											isManageLeague={false}
										/>
									);
								} else return null;
							})}
					</div>

					<div className="container mx-auto grid grid-cols-2 gap-4 py-4 md:gap-10 lg:grid-cols-4 xl:grid-cols-4">
						{/* First page: Show leagues from index 3 to 19. */}
						{data?.pages[0]?.data.slice(3, hasNextPage ? 19 : 20).map((league, leagueIndex) => (
							<>
								<LeagueCard
									key={`${league.LeagueID}`}
									league={league}
									onLeagueClick={(league) => setActiveLeague(league)}
									isManageLeague={false}
								/>
								{leagueIndex === 15 && (
									<RenderAd
										zoneId="679340"
										pageId={100008}
										place={0}
										ipAddress={ipAddress}
										containerClassname="col-span-full grid w-full"
										numberOfDifferentZoneImages={numberOfDifferentBannerImages}
										setNumberOfDifferentZoneImages={setNumberOfDifferentBannerImages}
									/>
								)}
							</>
						))}

						{/* Show the last league from the previous page and the first 19 leagues from the current page */}
						{data?.pages.slice(1).map((page, pageIndex) => {
							// Get the last league from the previous page
							const lastLeagueOfPrevPage = data.pages[pageIndex]?.data.slice(-1)[0];
							const leaguesToShow = [lastLeagueOfPrevPage];

							// Add the first 19 leagues from the current page to the array
							// If !hasNextPage -> show all remaining data from the current page
							leaguesToShow.push(...page.data.slice(0, hasNextPage ? 19 : 20));

							return leaguesToShow.map((league, index) => {
								const RenderLeagueCard = () => (
									<LeagueCard
										league={league as LeagueSearch}
										onLeagueClick={(league) => setActiveLeague(league)}
										isManageLeague={false}
									/>
								);

								if (index < leaguesToShow.length - 1 && league) {
									return <RenderLeagueCard key={`${league.LeagueID}`} />;
								}

								return (
									league && (
										<React.Fragment key={`${league.LeagueID}`}>
											<RenderLeagueCard />
											<RenderAd
												zoneId="679340"
												pageId={100008}
												place={pageIndex + 2}
												ipAddress={ipAddress}
												containerClassname="col-span-full grid w-full"
												numberOfDifferentZoneImages={numberOfDifferentBannerImages}
												setNumberOfDifferentZoneImages={setNumberOfDifferentBannerImages}
											/>
										</React.Fragment>
									)
								);
							});
						})}
					</div>
				</>
			)}

			{hasNextPage && (
				<div className="my-12 flex items-center justify-center">
					<Button
						variant="secondary"
						disabled={!hasNextPage || isFetching}
						aria-disabled={!hasNextPage || isFetching}
						onClick={async () => {
							fetchNextPage();
						}}
					>
						{isFetching ? 'Loading more...' : 'Load more'}
						<FontAwesomeIcon icon={isFetching ? faSpinner : faArrowRight} className="ml-2" spin={isFetching} />
					</Button>
				</div>
			)}
			<AnimatePresence>
				{activeLeague && (
					<div className="fixed inset-0 z-50 flex items-center justify-center p-4">
						<motion.div className="fixed inset-0 bg-black bg-black/75" onClick={() => setActiveLeague(undefined)} />
						<motion.div
							layoutId={`league-card-${activeLeague.LeagueID}`}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.2 }}
							className="relative flex w-full max-w-2xl flex-col"
						>
							<div> {renderLeagueCardLabels(activeLeague, true)}</div>
							<div className="flex w-full flex-col md:flex-row">
								<div
									className="absolute -right-4 -top-4 z-50 flex size-8 cursor-pointer items-center justify-center rounded-full bg-white"
									onClick={() => {
										setActiveLeague(undefined);
									}}
								>
									<FontAwesomeIcon icon={faTimes} className="" />
								</div>
								<div className="relative z-10 flex-1 border-b border-gray-200 bg-white md:rounded-bl-md md:border-r">
									<div className="relative">
										<EventCard.Image
											imageSrc={getPBracketsCDNURL(
												`https://pickleballbrackets.com/uploads/clubs/${activeLeague.ClubID}/${
													activeLeague.LeagueLogo ? activeLeague.LeagueLogo : activeLeague.ClubLogo
												}`,
												383,
												240
											)}
											className="h-44 md:h-60"
										/>
										<div
											className={classNames(
												'absolute -bottom-4 right-2 flex h-8 w-8 items-center justify-center rounded-full',
												getLeagueLabelColorClass(activeLeague)
											)}
										>
											<FontAwesomeIcon icon={faMoneyBill} className="text-white" />
										</div>
									</div>
									<EventCard.Content>
										<p className="text-sm uppercase tracking-wide text-gray-600 md:mb-1">
											{activeLeague.Registration_DateOpen &&
												activeLeague.Registration_DateClosed &&
												`${moment.utc(activeLeague.Registration_DateOpen).format('MMM DD, YYYY')} - ${moment
													.utc(activeLeague.Registration_DateClosed)
													.format('MMM DD, YYYY')}`}
										</p>
										<p className="text-sm uppercase tracking-wide text-gray-600 md:mb-1">
											{activeLeague.FirstMatchDate && `Start: ${moment.utc(activeLeague.FirstMatchDate).format('h:mm A')}`}
										</p>
										<p className={`mt-2 line-clamp-2 text-base font-bold uppercase leading-6 text-gray-900 md:mb-2 md:text-xl`}>
											{activeLeague.LeagueTitle}
										</p>
										<p className="mt-auto flex items-center pt-2 font-body text-sm font-semibold text-gray-600 md:text-base">
											{activeLeague.ClubTitle}
										</p>
										<p className="mt-auto flex items-center pt-2 font-body text-sm text-gray-600 md:text-base">
											{`${activeLeague.City}, ${activeLeague.StateTitle}, ${activeLeague.CountryTitle}`}
										</p>
									</EventCard.Content>
									<EventCard.Footer>
										<EventCard.PlayerCount count={activeLeague.TotalRegistered} />
									</EventCard.Footer>
								</div>
								<div className="relative flex w-full flex-col justify-center rounded-b-md bg-white p-2 md:w-72 md:rounded-bl-none md:rounded-br-md">
									<div className="my-2 md:my-4">
										<div className="px-4 text-xs font-medium uppercase tracking-wider text-gray-400">Menu:</div>
										<ul className="mt-2 p-0">
											<li className="list-none md:mb-2">
												<Link
													href={
														checkIfValidUUID(activeLeague.LeagueID)
															? `${process.env.NEXT_PUBLIC_PL_URL}/leagues/${activeLeague.slug}/session/${activeLeague.LeagueSessionID}/standings`
															: `${process.env.NEXT_PUBLIC_PBRACKETS_URL}/lgd.aspx?lid=${activeLeague.LeagueID}`
													}
													target={checkIfValidUUID(activeLeague.LeagueID) ? '_self' : '_blank'}
													className={classNames('block w-full rounded-md  px-4 py-1.5 text-sm transition duration-150 ', {
														'bg-brand-500 text-white hover:bg-brand-700': !activeLeague.Registration_DateClosed,
														'decoration-black underline-offset-2 hover:underline': activeLeague.Registration_DateClosed
													})}
												>
													Details
													{!checkIfValidUUID(activeLeague.LeagueID) && (
														<FontAwesomeIcon icon={faExternalLink} className="ml-2 text-sm" />
													)}
												</Link>
											</li>
											<li className="mb-1 list-none md:mb-2">
												<Link
													href={`${process.env.NEXT_PUBLIC_PL_URL}/leagues/${activeLeague.slug}/session/${activeLeague.LeagueSessionID}/matchdate/rsvp`}
													className="px-4 text-sm decoration-black underline-offset-2 hover:underline"
												>
													Current Game Day
												</Link>
											</li>
											<li className="mb-1 list-none md:mb-2">
												<Link
													href={`${process.env.NEXT_PUBLIC_PL_URL}/leagues/${activeLeague.slug}/session/${activeLeague.LeagueSessionID}/schedule`}
													className="px-4 text-sm decoration-black underline-offset-2 hover:underline"
												>
													Schedule
												</Link>
											</li>
											<li className="list-none md:mb-2">
												<Link
													href={`${contactALeagueURL}/${activeLeague.LeagueID}`}
													className="px-4 text-sm decoration-black underline-offset-2 hover:underline"
												>
													Contact League
												</Link>
											</li>

											{user && user.session && activeLeague && (isSuperAdmin || activeLeague.mng === 1) && (
												<li className="list-none md:mb-2">
													<Link
														href={
															isSuperAdmin
																? `${process.env.NEXT_PUBLIC_PBRACKETS_URL}/a5_a/l/sesss.aspx?lid=${activeLeague.LeagueID}&uid=&cid=${activeLeague.ClubID}`
																: `${process.env.NEXT_PUBLIC_PBRACKETS_URL}/a5_u/l/sesss.aspx?lid=${activeLeague.LeagueID}&uid=&cid=${activeLeague.ClubID}`
														}
														className="block w-full rounded-md bg-darkblue-500 px-4 py-1.5 text-sm text-white transition duration-150 hover:bg-darkblue-700"
													>
														Manage League
													</Link>
												</li>
											)}
										</ul>
									</div>
									{activeLeague.TitleSponsorLogo !== '' && activeLeague.TitleSponsorLogo !== undefined && (
										<div className="relative mx-4 flex h-20 items-center justify-center rounded-lg">
											<Link target="_blank" href={activeLeague.TitleSponsorLogo}>
												<ImageWithFallback
													fill
													className="object-contain"
													src={activeLeague.TitleSponsorLogo}
													alt={activeLeague.TitleSponsorTitle + 'logo image'}
												/>
											</Link>
										</div>
									)}
								</div>
							</div>
						</motion.div>
					</div>
				)}
			</AnimatePresence>
		</>
	);
};

interface LeagueCardProps {
	league: LeagueSearch;
	onLeagueClick: (league: LeagueSearch) => void;
	isManageLeague: boolean;
}

export const getLeagueLabelColorClass = (league: LeagueSearch) => {
	// TODO Cancel League "bg-error-500";
	if (league.IsLocked === false) {
		if (moment(league.Registration_DateClosed).isBefore(moment())) return 'bg-success-700';
		if (league.Registration_DateClosed) return 'bg-success-500';
		if (league.IsRegClosed === 0 || league.IsRegClosed === 1) {
			return 'bg-success-700';
		} else return 'bg-gray-900';
	} else return 'bg-gray-900';
	//TODO cost registration current
};

export function renderLeagueCardLabels(league: LeagueSearch, rounded: boolean = true) {
	const htmlToRender = [];
	// TODO Cancel League
	{
		if (league.IsLocked === true) {
			htmlToRender.push(
				<div
					className={`tracking-wider text-white ${getLeagueLabelColorClass(league)} px-4 py-1 text-center text-xs font-semibold text-white ${
						rounded ? 'rounded-t-md' : ''
					}`}
				>
					Completed
				</div>
			);
		} else {
			if (league.IsRegClosed === 0) {
				if (parseFloat(league.Cost_Registration_Member) > 0) {
					htmlToRender.push(
						<div
							className={`tracking-wider text-white ${getLeagueLabelColorClass(league)} px-4 py-1 text-center text-xs font-semibold text-white ${
								rounded ? 'rounded-t-md' : ''
							}`}
						>
							{`USD $${league.Cost_Registration_Member}`}
						</div>
					);
				} else {
					htmlToRender.push(
						<div
							className={`tracking-wider text-white ${getLeagueLabelColorClass(league)} px-4 py-1 text-center text-xs font-semibold text-white ${
								rounded ? 'rounded-t-md' : ''
							}`}
						>
							Register
						</div>
					);
				}
			} else {
				htmlToRender.push(
					<div
						className={`tracking-widertext-white ${getLeagueLabelColorClass(league)} px-4 py-1 text-center text-xs font-semibold text-white ${
							rounded ? 'rounded-t-md' : ''
						}`}
					>
						View
					</div>
				);
			}
		}
	}
	return htmlToRender;
}

export const LeagueCard = ({ league, onLeagueClick, isManageLeague }: LeagueCardProps) => {
	const renderLabels = React.useMemo(() => renderLeagueCardLabels(league), [league]);

	return (
		<div key={league.LeagueID} className="relative flex w-full max-w-lg">
			<EventCard onClick={() => onLeagueClick(league)}>
				<div className="relative">
					{/* TODO is fetured*/}
					{!league.IsRegClosed && (
						<div className="">
							<FontAwesomeIcon
								icon={faStar}
								className="absolute left-1 top-1/2 -mt-[7px] text-sm text-white"
								data-tooltip-id="tooltip"
								data-tooltip-content="Featured league."
							/>
						</div>
					)}
					{renderLabels.map((item, index) => (
						<React.Fragment key={index}>{item}</React.Fragment>
					))}
				</div>
				<div className="relative">
					<EventCard.Image
						imageSrc={
							league.LeagueLogo || league.ClubLogo
								? getPBracketsCDNURL(
										`https://pickleballbrackets.com/uploads/clubs/${league.ClubID}/${league.LeagueLogo ? league.LeagueLogo : league.ClubLogo}`,
										282,
										176
									)
								: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/placeholder/pickleball_transparent_square.png`, 219, 176)
						}
						alt={`${league.LeagueTitle} Logo`}
					/>

					<div
						className={classNames(
							'absolute bottom-2 right-1 flex h-8 w-8 items-center justify-center rounded-full',
							getLeagueLabelColorClass(league)
						)}
					>
						<FontAwesomeIcon icon={faMoneyBill} className="text-white" />
					</div>
				</div>
				<EventCard.Content>
					{league.Registration_DateOpen && league.Registration_DateClosed ? (
						<p className="mb-1 text-xs text-gray-600 md:text-sm">
							{`${moment.utc(league.Registration_DateOpen).format('MMM DD, YYYY')}-${moment
								.utc(league.Registration_DateClosed)
								.format('MMM DD, YYYY')}`}
						</p>
					) : null}
					{league.FirstMatchDate ? (
						<p className="mb-1 text-xs text-gray-600 md:text-sm">{`Start: ${moment.utc(league.FirstMatchDate).format('h:mm A')}`}</p>
					) : null}
					<p className={`my-2 line-clamp-3 font-bold uppercase leading-6 text-gray-900`}>{league.LeagueTitle}</p>
					<p className="mt-auto flex items-center pt-2 text-sm font-semibold text-gray-600">
						{/** TODO location */}
						{league.ClubTitle}
					</p>
					{league.City && league.StateTitle && league.CountryTitle ? (
						<p className="mt-auto flex items-center pt-2 text-xs text-gray-600 md:text-sm">
							{/** TODO location */}
							{`${league.City}, ${league.StateTitle}, ${league.CountryTitle}`}
						</p>
					) : null}
				</EventCard.Content>

				<EventCard.Footer>
					{isManageLeague === true ? (
						<>
							<EventCard.SessionCount count={league.SessionNumber_Cur} />
							<EventCard.GameDaysCount count={league.MatchDateCount_Cur} />
							<EventCard.PlayerCount count={league.SessionPlayerCount_Cur} />
						</>
					) : (
						<EventCard.PlayerCount count={league.TotalRegistered} />
					)}
				</EventCard.Footer>
			</EventCard>
		</div>
	);
};
