import { faFilterList, faLoader, faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer } from '@pickleballinc/react-ui';
import { useQuery } from '@tanstack/react-query';
import { Country, State } from 'protos/model';
import React, { useState } from 'react';

import { Accordion } from '@/components/Accordion/Accordion';
import { Button } from '@/components/Button/Button';

import { FilterState } from './Filters';
import { formatsData, playerGroupData, weekdayData } from './LeagueFilters';

interface MobileLeagueFiltersProps {
	filters: FilterState;
	updateFilter: <T extends FilterState, K extends keyof T>(key: K, value: T[K]) => void;
	resetFilters: () => void;
	onSubmit: () => void;
}

export const MobileLeagueFilters: React.FC<MobileLeagueFiltersProps> = ({ filters, updateFilter, resetFilters, onSubmit }) => {
	const [mobileFiltersToggled, setMobileFiltersToggled] = useState(false);
	const [activeKey, setActiveKey] = useState<string[]>([]);
	const [formatsGroupPlayersData, setFormatsGroupPlayersData] = useState(formatsData);

	const { data: countries } = useQuery(
		['countries'],
		async () => {
			const response = await fetch('/api/getLeagues/getCountries');
			const responseData = (await response.json()) as { data: { results: Country[] } };
			return responseData.data;
		},
		{
			refetchOnWindowFocus: false,
			initialData: { results: [] }
		}
	);

	const { data: states, isFetching: statesLoading } = useQuery(
		['states', filters.country.id],
		async () => {
			const response = await fetch(`/api/getLeagues/getStates?countryId=${filters.country.id}`);
			const responseData = (await response.json()) as { data: { results: State[] } };
			return responseData.data;
		},
		{
			refetchOnWindowFocus: false,
			enabled: filters.country.id !== '',
			initialData: { results: [] }
		}
	);

	const getFormatsForPlayerGroup = (playerGroupValue: string) => {
		if (playerGroupValue === '3' && formatsData[1] && formatsData[2]) {
			setFormatsGroupPlayersData([formatsData[1], formatsData[2]]);
		} else {
			setFormatsGroupPlayersData(formatsData);
		}
	};

	return (
		<div className={`sticky top-20 ${mobileFiltersToggled ? 'z-50' : 'z-40'}`}>
			<button
				className={`flex w-full items-center justify-center border-y px-4 py-3 text-center uppercase tracking-wider ${
					mobileFiltersToggled ? 'border-darkblue-500 bg-darkblue-500 text-white' : 'border-gray-200 bg-white text-black'
				}`}
				onClick={() => setMobileFiltersToggled(!mobileFiltersToggled)}
			>
				<FontAwesomeIcon icon={faFilterList} className="mr-2" />
				<span className="text-xs">Filters</span>
			</button>
			<Drawer
				className="h-screen overflow-auto"
				title="Filter Leagues"
				open={mobileFiltersToggled}
				onBackdropClick={() => setMobileFiltersToggled(false)}
				onClose={() => setMobileFiltersToggled(false)}
			>
				{mobileFiltersToggled && (
					<div className="flex w-full flex-col justify-between bg-white">
						<Accordion activeEventKey={activeKey} onToggle={setActiveKey} element="div">
							<Accordion.Toggle className="block w-full" eventKey="status" element="button">
								<span className="flex items-center border-b border-gray-200 p-4 text-xs uppercase tracking-wider">
									Status
									<FontAwesomeIcon icon={activeKey.includes('status') ? faMinus : faPlus} className="ml-auto block" />
								</span>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="status">
								<div className="flex items-center border-b border-gray-200 p-4">
									<Button
										variant="gray"
										buttonVariantType={filters.status === 'active' ? 'contained' : 'outlined'}
										className="ml-2"
										onClick={() => updateFilter('status', 'active')}
									>
										Active
									</Button>
									<Button
										variant="gray"
										buttonVariantType={filters.status === 'completed' ? 'contained' : 'outlined'}
										className="ml-2"
										onClick={() => updateFilter('status', 'completed')}
									>
										Completed
									</Button>
								</div>
							</Accordion.Collapse>

							<Accordion.Toggle className="block w-full" eventKey="country" element="button">
								<span className="flex items-center border-b border-gray-200 p-4 text-xs uppercase tracking-wider">
									Country
									<FontAwesomeIcon icon={activeKey.includes('country') ? faMinus : faPlus} className="ml-auto block" />
								</span>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="country">
								<div className="flex max-h-56 flex-col overflow-y-auto border-b border-gray-200 p-4">
									{countries.results.map((ct) => (
										<div
											key={ct.id}
											className={`mt-0.5 w-full flex-1 rounded px-4 py-1.5 text-left ${
												filters.country.id === ct.id.toString() ? 'bg-gray-200' : ''
											}`}
											onClick={() => {
												updateFilter('country', { id: ct.id.toString(), stateId: '' });
												setActiveKey(['state']);
											}}
										>
											{ct.title}
										</div>
									))}
								</div>
							</Accordion.Collapse>

							<Accordion.Toggle className="block w-full" eventKey="state" element="button">
								<span className="flex items-center border-b border-gray-200 p-4 text-xs uppercase tracking-wider">
									State
									<FontAwesomeIcon icon={activeKey.includes('state') ? faMinus : faPlus} className="ml-auto block" />
								</span>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="state">
								<div className="flex max-h-56 flex-col overflow-y-auto border-b border-gray-200 p-4">
									{statesLoading ? (
										<div className="flex flex-col items-center text-sm italic">
											<FontAwesomeIcon icon={faLoader} spin className="mb-2" />
											Loading states...
										</div>
									) : states.results.length === 0 ? (
										<div className="px-4 text-sm italic text-gray-500">
											First select{' '}
											<span
												className="text-xs uppercase tracking-wider text-secondary-500 underline decoration-secondary-500 underline-offset-2"
												onClick={() => setActiveKey(['country'])}
											>
												Country
											</span>
										</div>
									) : (
										states.results.map((st) => (
											<div
												key={st.id}
												className={`mt-0.5 rounded px-4 py-1.5 ${filters.country.stateId === st.id.toString() ? 'bg-gray-200' : ''}`}
												onClick={() =>
													updateFilter(
														'country',
														filters.country.stateId === st.id.toString()
															? { id: filters.country.id, stateId: '' }
															: { id: filters.country.id, stateId: st.id.toString() }
													)
												}
											>
												{st.title}
											</div>
										))
									)}
								</div>
							</Accordion.Collapse>

							<Accordion.Toggle className="block w-full" eventKey="playerGroup" element="button">
								<span className="flex items-center border-b border-gray-200 p-4 text-xs uppercase tracking-wider">
									Player Group
									<FontAwesomeIcon icon={activeKey.includes('playerGroup') ? faMinus : faPlus} className="ml-auto block" />
								</span>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="playerGroup">
								<div className="flex max-h-56 flex-col overflow-y-auto border-b border-gray-200 p-4">
									{playerGroupData.map((pg) => (
										<div
											key={pg.value}
											className={`mt-0.5 rounded px-4 py-1.5 ${filters.playerGroupsId === pg.value ? 'bg-gray-200' : ''}`}
											onClick={() => {
												updateFilter('playerGroupsId', filters.playerGroupsId === pg.value ? '' : pg.value);
												getFormatsForPlayerGroup(pg.value);
											}}
										>
											{pg.label}
										</div>
									))}
								</div>
							</Accordion.Collapse>

							<Accordion.Toggle className="block w-full" eventKey="formats" element="button">
								<span className="flex items-center border-b border-gray-200 p-4 text-xs uppercase tracking-wider">
									Formats
									<FontAwesomeIcon icon={activeKey.includes('formats') ? faMinus : faPlus} className="ml-auto block" />
								</span>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="formats">
								<div className="flex max-h-56 flex-col overflow-y-auto border-b border-gray-200 p-4">
									{formatsGroupPlayersData.map((fd) => (
										<div
											key={fd.value}
											className={`mt-0.5 rounded px-4 py-1.5 ${filters.formatsId === fd.value ? 'bg-gray-200' : ''}`}
											onClick={() => updateFilter('formatsId', filters.formatsId === fd.value ? '' : fd.value)}
										>
											{fd.label}
										</div>
									))}
								</div>
							</Accordion.Collapse>

							<Accordion.Toggle className="block w-full" eventKey="weekday" element="button">
								<span className="flex items-center border-b border-gray-200 p-4 text-xs uppercase tracking-wider">
									Weekday
									<FontAwesomeIcon icon={activeKey.includes('weekday') ? faMinus : faPlus} className="ml-auto block" />
								</span>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="weekday">
								<div className="flex max-h-56 flex-col overflow-y-auto border-b border-gray-200 p-4">
									{weekdayData.map((wd) => (
										<div
											key={wd.value}
											className={`mt-0.5 rounded px-4 py-1.5 ${
												filters.weekDays.parameter === wd.parameter && filters.weekDays.value === wd.value
													? 'bg-gray-200'
													: ''
											}`}
											onClick={() =>
												updateFilter(
													'weekDays',
													filters.weekDays.parameter === wd.parameter && filters.weekDays.value === wd.value
														? { parameter: '', value: '' }
														: { parameter: wd.parameter, value: wd.value }
												)
											}
										>
											{wd.label}
										</div>
									))}
								</div>
							</Accordion.Collapse>
						</Accordion>

						<div className="flex items-center p-4">
							<Button
								variant="primary"
								buttonVariantType="contained"
								buttonSize="sm"
								className="mr-2 flex-1 justify-center !border-brand-700 !bg-brand-700 font-bold tracking-wider hover:!bg-brand-800"
								onClick={() => {
									onSubmit();
									setMobileFiltersToggled(false);
								}}
							>
								Apply
							</Button>
							<Button
								variant="gray"
								buttonVariantType="outlined"
								className="ml-2 flex-1 justify-center font-bold"
								buttonSize="sm"
								onClick={() => {
									resetFilters();
									setMobileFiltersToggled(false);
								}}
							>
								Reset
							</Button>
						</div>
					</div>
				)}
			</Drawer>
		</div>
	);
};
